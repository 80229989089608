import './App.css'
import LoginSingup from './Pages/LoginSingup'
import Shop from './Pages/Shop'
import ShopCategory from './Pages/ShopCategory'
import Footer from './components/Footer/Footer'
import Navbar from './components/Navbar/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import men_banner from './components/Assets/banner_mens.png'
import women_banner from './components/Assets/banner_women.png'
import kid_banner from './components/Assets/banner_kids.png'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route path="/mans" element={<ShopCategory banner={men_banner} category="mens" />} />
          <Route path="/womans" element={<ShopCategory banner={women_banner} category="womens" />} />
          <Route path="/kids" element={<ShopCategory banner={kid_banner}  category="kids" />} />
          <Route path="/product" element={<product />} />
          <Route path=":productId" element={<product />} />
          <Route path="/cart" element={<cart />} />
          <Route path="/login" element={<LoginSingup />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App
