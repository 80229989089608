import React, { useContext, useState } from 'react'
import './Navbar.css'
import logo from '../Assets/logo.png'
import cart_icon from '../Assets/cart_icon.png'
import { Link } from 'react-router-dom'
import { ShopContext } from '../../context/ShopContext'

const Navbar = () => {
  const [menu, setMenu] = useState('shop')
  const {getTotalcartAmount} =useContext(ShopContext)

  return (
    <div className="navbar">
      <div className="nav-logo">
        <img src={logo} alt="" />
        <p>HaatMax</p>
      </div>
      <ul className="nav-menu">
        <li
          onClick={() => {
            setMenu('Shop')
          }}
        >
          <Link style={{ textDecoration: 'none' }} to="/">
            All Shop
          </Link>{' '}
          {menu === 'shop' ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu('mans')
          }}
        >
          <Link style={{ textDecoration: 'none' }} to="/mans">
            man
          </Link>{' '}
          {menu === 'womans' ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu('Womans')
          }}
        >
          <Link style={{ textDecoration: 'none' }} to="/womans">
            woman
          </Link>{' '}
          {menu === 'womans' ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu('kids')
          }}
        >
          <Link style={{ textDecoration: 'none' }} to="kids">
            Kid
          </Link>{' '}
          {menu === 'kids' ? <hr /> : <></>}
        </li>
      </ul>
      <div className="nav-login-cart">
        <Link to="/login">
          <button>Login</button>
        </Link>
        <Link to="/cart">
          <img src={cart_icon} alt="" />
        </Link>
        <div className="nav-cart-count">{getTotalcartAmount}()</div>
      </div>
    </div>
  )
}

export default Navbar
