import React from 'react'
import './CSS/LoginSignup.css'

const LoginSingup = () => {
  return (
    <div className="loginsignup">
      <div className="loginsignup-container">
        <h1>Sign Up</h1>
        <div className="loginsignup-fields">
          <input type="text" placeholder="Your name" />
          <input type="email" placeholder="Email address" />
          <input type="password" placeholder="password" />
        </div>
        <button>Continue</button>
        <p className="loginsignup-login">
          already have on account ? <span>Login here</span>
        </p>
        <div className="loginsignup-agree">
          <input type="checbox" name="" id="" />
          <p>by continuing, i agree to the terms of use & privcy plolicy</p>
        </div>
      </div>
    </div>
  )
}

export default LoginSingup
